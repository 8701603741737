// Generated by Framer (c563d2c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Av5ntZ2hx", "Uuk9cVMtL", "tjzZF4Cjv", "XsMuo2qUg"];

const serializationHash = "framer-oJKBt"

const variantClassNames = {Av5ntZ2hx: "framer-v-17h30t4", tjzZF4Cjv: "framer-v-q5i5dn", Uuk9cVMtL: "framer-v-urmbng", XsMuo2qUg: "framer-v-1tx57ot"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"LP-desktop-menu-close": "Av5ntZ2hx", "Phone Open": "XsMuo2qUg", Phone: "tjzZF4Cjv", Tablet: "Uuk9cVMtL"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Av5ntZ2hx"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Av5ntZ2hx", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-17h30t4", className, classNames)} data-border data-framer-name={"LP-desktop-menu-close"} layoutDependency={layoutDependency} layoutId={"Av5ntZ2hx"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgba(0, 0, 0, 0.08)", "--border-left-width": "0px", "--border-right-width": "0px", "--border-style": "solid", "--border-top-width": "0px", backgroundColor: "rgb(255, 255, 255)", ...style}} {...addPropertyOverrides({tjzZF4Cjv: {"data-framer-name": "Phone"}, Uuk9cVMtL: {"data-framer-name": "Tablet"}, XsMuo2qUg: {"data-framer-name": "Phone Open"}}, baseVariant, gestureVariant)}/></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-oJKBt.framer-1f2ja2a, .framer-oJKBt .framer-1f2ja2a { display: block; }", ".framer-oJKBt.framer-17h30t4 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 24px; height: 80px; justify-content: center; padding: 24px; position: relative; width: 1200px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-oJKBt.framer-17h30t4 { gap: 0px; } .framer-oJKBt.framer-17h30t4 > * { margin: 0px; margin-left: calc(24px / 2); margin-right: calc(24px / 2); } .framer-oJKBt.framer-17h30t4 > :first-child { margin-left: 0px; } .framer-oJKBt.framer-17h30t4 > :last-child { margin-right: 0px; } }", ".framer-oJKBt.framer-v-urmbng.framer-17h30t4 { width: 810px; }", ".framer-oJKBt.framer-v-q5i5dn.framer-17h30t4 { width: 390px; }", ".framer-oJKBt.framer-v-1tx57ot.framer-17h30t4 { flex-direction: column; height: min-content; min-height: 295px; padding: 24px 24px 60px 24px; width: 390px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-oJKBt.framer-v-1tx57ot.framer-17h30t4 { gap: 0px; } .framer-oJKBt.framer-v-1tx57ot.framer-17h30t4 > * { margin: 0px; margin-bottom: calc(24px / 2); margin-top: calc(24px / 2); } .framer-oJKBt.framer-v-1tx57ot.framer-17h30t4 > :first-child { margin-top: 0px; } .framer-oJKBt.framer-v-1tx57ot.framer-17h30t4 > :last-child { margin-bottom: 0px; } }", ".framer-oJKBt[data-border=\"true\"]::after, .framer-oJKBt [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 80
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Uuk9cVMtL":{"layout":["fixed","fixed"]},"tjzZF4Cjv":{"layout":["fixed","fixed"]},"XsMuo2qUg":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerKzBliLOiB: React.ComponentType<Props> = withCSS(Component, css, "framer-oJKBt") as typeof Component;
export default FramerKzBliLOiB;

FramerKzBliLOiB.displayName = "Navbar Copy";

FramerKzBliLOiB.defaultProps = {height: 80, width: 1200};

addPropertyControls(FramerKzBliLOiB, {variant: {options: ["Av5ntZ2hx", "Uuk9cVMtL", "tjzZF4Cjv", "XsMuo2qUg"], optionTitles: ["LP-desktop-menu-close", "Tablet", "Phone", "Phone Open"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerKzBliLOiB, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})